import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "the-building-blocks"
    }}>{`The building blocks`}</h1>
    <p>{`React for Dataviz builds on a set of core building blocks.`}</p>
    <p>{`You start with D3 blackbox components that wrap D3 code in React components. Follow up with a thorough integration that's more scalable, then put it all together using React Hooks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      